.header-block{
    width: 100%;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    height: 70px;
    position: absolute;
    top:0;
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

.header-block > *{
    height: 100%;
}

.header-left {
    width: 50%;
    display: flex;
    font-size: 20px;
}

.header-left > a {
    min-width: 100px;
    width: 100px;
    color: var(--primary-color);

    display: flex;
    justify-content: center;
    align-items: center;
}

.header-left > a, .header-right > a {
    transition: 200ms ease-in;
}

.header-left > a:hover, .header-left > a:focus{
    border-color: var(--accent-color);
    color: var(--accent-color);
}

.header-left > .active {
    text-decoration: none;
    background-color: rgba(115,53,39, 0.5);
    color: white;
}

.header-right > a:focus, .header-right > a:hover{
    color: var(--primary-color);
}

.header-right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background-color: var(--accent-color);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    width: 25%;
}

.header-right h1{
    margin-top: auto;
    margin-bottom: auto;
}

.mobile-left{
    display: none;
    position: absolute;
    cursor: pointer;
    width: var(--header-height);
    height: var(--header-height);
}

.mobile-left svg{
    width: 50px;
    height: 50px;
}

a {
    color: var(--secondary-color);
    text-decoration: none;
}

#loading-icon {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
}

@media (max-width: 768px) {
    .header-block {
        background-color: var(--accent-color);
        color: var(--secondary-color);
    }
    .header-left {
        display: none;
    }
    .mobile-left{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header-right {
        margin: auto;
        width: auto;
    }
}
