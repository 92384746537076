.admin {
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.admin h1{
    font-family: Archivo;
}

.admin-header {
    width: 100%;
    background-color: rgba(118,53,39,0.8);
    padding: 1rem 2rem;
    color:var(--secondary-color)
}

table {
    width: 100%;
    padding: 20px;
    border-collapse: collapse;
}

table th, table td {
    border: 1px solid black;
    padding: 10px;
}

td img{
    max-height: 60px;
}

table th {
    background-color: var(--accent-color);
    color: var(--secondary-color);
    border: none;
    font-size: 1.3em;
}

table tr td:first-child {
    background-color: var(--accent-color);
    color: white;
    cursor: pointer;
}

thead {
    border: 1px solid black;
}

tbody {
    overflow-y: scroll;
}

#content-main {
    width: calc(100% - 200px);
}

#table-block, #content-block {
    height: calc(100% - 120px);
    overflow-y: scroll;
    padding: 20px;
}

#icon-hover {
    display: none;
    width: 100px;
    height: 100px;
    position: sticky;
}

#newsletter-title {
    width: 100%;
    padding: 2px;
    margin-bottom: 10px;
}

.image-cell {
    display: flex;
    justify-content: center;
}

#btn-logout {
    position: absolute;
    bottom: 10px;
    right: 20px;
}

#loading-icon {
    display: none;
    width: 40px;
    height: 40px;
    margin: auto;
}

#table-header {
    margin-bottom: 1em;
}

.quill {
    margin-bottom: 1em;
}

.ql-editor {
    height: 45vh !important;
}

.required {
    color: red;
}

@media(max-width: 768px) {
    #content-main {
        margin-top: 50px;
        width: 100%;
    }
    #table-block, #content-block {
        height: calc(100% - 170px);
    }
    table th, table td {
        padding: 4px;
    }
    td img {
        height: 60px;
    }

    tr {
        font-size: 10px;
    }

    table {
        width: 90vw;
    }
}