@import url('https://fonts.googleapis.com/css2?family=Archivo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Pacifico&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Archivo','Pacifico', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Archivo, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}
 h1 {
   font-family: Pacifico;
   margin-top: 0;
   margin-bottom: 0;
 }

 button, input[type=checkbox] {
     cursor: pointer;
 }

 :root {
   --header-height: 70px;
   --footer-height: 240px;
   --primary-color: #181818;
   --secondary-color: #FFFFFF;
   --accent-color: #763527;
 }
