/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#popup-back {
    display: none;
    z-index: 100;
    position: fixed;
    background-color: rgba(24,24,24,0.5);
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

#admin-art-form, #admin-event-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#admin-art-form > span, #admin-event-form > span{
    text-align: right;
    padding-right: 10px;
    font-size: 12px;
}

#popup-back.active {
    display: flex;
}

#popup-main {
    z-index: 101;
    width: 80vw;
    height: 80vh;
    background-color: var(--secondary-color);
    border-radius: 1rem;
}

#form-top {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
    height: 80%;
    overflow-y: scroll;
    width: min(600px, 95%);
    margin: auto;
}

#form-top > input[type=text], #form-top > input[type=number],
#form-top > input[type=url], #form-top > input[type=date] {
    width: 90%;
}

#form-top > input[type=checkbox] {
    width: 1.5em;
    height: 1.5em;
    filter: hue-rotate(180deg) saturate(0.3);
}

#form-top input {
    margin-left: 10px;
}

#form-bottom {
    height: 10%;
    display: flex;
    justify-content: space-evenly;
}

#form-dimensions input {
    width: 60px;
    min-height: 30px;
}

#form-dimensions strong {
    padding: 0px 10px;
}

#form-notice {
    color: red;
    width: 100%;
}