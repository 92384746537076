.contact-block {
    padding-bottom: calc(var(--footer-height) + 1rem);
    font-size: 20px;
}

.contact-block a:hover {
    color: var(--accent-color);
}

.contact-info {
    width: 70%;
    margin: auto;
}

.contact-info a {
    color: var(--primary-color);
}

.contact-info svg {
    margin-right: 0.5em;
}

.contact-form {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
    font-size: 20px;
}

.contact-form label {
    color: var(--accent-color);
    font-weight: 600;
}

.form-item {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}

.form-item > input {
    padding: 5px;
    font-size: 20px;
}

.form-item > textarea {
    min-height: 40vh;
}

#form-submit {
    background-color: var(--accent-color);
    color: var(--secondary-color);
    font-weight: 600;
}

