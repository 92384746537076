#banner {
    padding-top: calc(var(--header-height));
    width: 100%;
    height: 300px;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    background-blend-mode: darken;
    display: flex;
    justify-content: center;
    align-items: center;
}

#banner h1{
    color: var(--secondary-color);
    font-size: 4em;
}