.footer-block {
    width: 100%;
    background-color: var(--accent-color);
    color: white;
    height: 240px;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.footer-left {
    width: 90%;
    max-width: 800px;
    margin: auto;
}

.footer-left h2 {
    text-align: center;
}

.footer-right {
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-evenly;
}

.footer-right svg {
    width: 2.5rem;
    height: 2.5rem;
    transition: 100ms ease-in;
}

.footer-right *:hover {
    color: var(--primary-color);
}

.newsletter-form > * {
    margin-top: 5px;
    margin-bottom: 5px;
}

.newsletter-form input[type=email] {
    display: block;
    margin: auto;
    width: 80%;
    padding: 5px;
    font-size: 20px;
}

.form-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
}
.form-bottom input[type=checkbox] {
    width: 20px;
    height: 20px;
}

.form-bottom #form-submit {
    font-size: 20px;
    padding: 5px 20px;
    border-radius: 0.2em;
    background-color: var(--secondary-color);
    color: var(--accent-color);
}

.social-link{
    display: flex;
    align-items: center;
}

.social-link > span {
    padding-left: 1em;
}
