.home-block {
    width: 100%;
    background-color: var(--secondary-color);
    min-height: 100%;
    padding-bottom: var(--footer-height);
    padding-top: var(--header-height);
}

.home-block #banner {
    height: 100vh;
}

.home-top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-image {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    background-blend-mode: darken;
}

.home-info {
    height: 600px;
    display: flex;
    font-size: 1.5em;
}

.home-info > * {
    padding: 5%;
}

.home-info > span {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-info-image {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-info-image img {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}
.forward {
    flex-direction: row;
    text-align: left;
}

.inverse {
    flex-direction: row-reverse;
    text-align: right;
}

@media (max-width: 425px) {
    .home-info {
        width: 80%;
        flex-direction: column;
        margin: 10% auto 10%;
        height: initial;
    }

    .home-info > span {
        width: 100%;
    }

    .home-info-image {
        width: 90%;
        display: flex;
        align-items: center;
    }

    .inverse {
        text-align: left;
    }

}