.art-block {
    margin: 0 2em;
    padding-bottom: calc(var(--footer-height) + 1rem);
}

.art-nav {
    display: flex;
    justify-content: space-evenly;
    margin: 30px 0;
}

.art-nav > button {
    border: none;
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    padding: 0.2em;
    background-color: var(--secondary-color);
    border-bottom: 0.2em solid transparent;
    transition: 200ms ease-in;
}

.art-nav > button:hover{
    border-color: rgba(115,53,39, 0.5);
}

.art-nav > .active {
    border-color: var(--accent-color) !important;
    color: var(--accent-color);
}

.list-container {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-auto-rows: 400px;
    gap: 2em;
}

.list-object{
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.list-object > h2 {
    height: 2.2em;
    vertical-align: center;
}

.list-image {
    max-width: 100%;
    max-height: 100%;
}

.middle-info {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2em;
}

.sale-item, .middle-info {
    transition: 500ms ease-in-out;
}

.sale-item:hover > .list-image {
    opacity: 0.4;
    filter: blur(3px);
    transition: 500ms ease-in-out;
}

.sale-item:hover>.middle-info {
    opacity: 1;
}

@media (max-width: 768px) {
    .list-container {
        grid-template-columns: minmax(0, 1fr);
    }
}