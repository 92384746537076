.about-block {
    padding-bottom: calc(var(--footer-height) + 1rem);
}

.about-top p {
    text-align: left;
}

.about-top > img {
    display: block;
    margin: auto;
    max-height: 85vh;
    padding: 2rem 0;
}

.about-top > img {
    max-width: 75%;
}

.about-image-bottom {
    max-width: 50%;
    display: block;
    margin: auto;
}

.bio-block {
    padding: 1rem;
    font-size: 1.3em;
}

.bio-block img{
    max-width: 30vw;
    max-height: 300px;
    float: right;
    margin: 2rem;
    border-radius: 10%;
}


