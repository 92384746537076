.events-block {
    padding-bottom: var(--footer-height);
    width: 90%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.events-block a {
    color: var(--accent-color);
}

.events-block h2 {
    text-align: center;
}

.events-block a:hover, .events-block a:focus {
    text-decoration: underline;
}

.events-block button {
    display: block;
    margin: 30px auto;
    font-size: 1.5rem;
    padding: 0.5em;
    border-radius: 0.5em;
    border-color: var(--accent-color);
    color: var(--accent-color);
    background-color: var(--secondary-color);
    cursor: pointer;
}

.event-item {
    padding: 30px 0;
    border-bottom: 1px solid var(--primary-color);
}

.event-item > p {
    margin-left: 5%;
}
