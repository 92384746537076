#desktop-nav {
    background-color: var(--secondary-color);
    width: 200px;
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    border-right: 1px solid var(--primary-color);
}

.nav-item {
    color: var(--accent-color);
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 1em 0 1em 1em;
    border-bottom: 2px solid var(--primary-color);
}

@media(max-width: 768px) {
    #desktop-nav {
        position: fixed;
        width: 100%;
        height: 50px;
    }
    .nav-section {
        height: inherit;
    }

    .nav-section * {
        padding-left: 0;
    }

    .nav-section > div {
        height: inherit;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .nav-item {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid var(--primary-color);
        border-right: 1px solid var(--primary-color);
    }
}