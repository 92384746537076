#mobile-nav-back {
    display: none;
    z-index: 100;
    position: fixed;
    background-color: rgba(24,24,24,0.5);
    width: 100vw;
    height: 100vh;
}

#mobile-nav-back.active {
    display: block;
}

#mobile-nav {
    min-width: 160px;
    width: 30%;
    height: 100%;
    background-color: var(--secondary-color);
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#mobile-nav a {
    display: flex;
    flex-direction: column;
    color: var(--primary-color);
    font-size: 1.2em;
    padding: 1em;
    border-bottom: 1px solid var(--primary-color);
}

#mobile-nav .active {
    text-decoration: none;
    background-color: rgba(115,53,39, 0.5);
}

#nav-bottom > .link-home {
    font-size: inherit;
    color: var(--primary-color);
    border-top: 2px solid var(--primary-color);
    text-align: center;
}