.alert-box{
    position: fixed;
    top: -1000px;
    width: 100%;
    transition: top 500ms ease-in-out;
}
.alert-box.active{
    top: 20px;
}

.alert-box > div {
    min-width: 200px;
    width: 30%;
    min-height: 100px;
    max-height: 30vh;
    background-color: white;
    margin: auto;
    padding: 0.5rem;
    border: 3px solid #181818;
    border-radius: 0.5rem;
}

.alert-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
}

.alert-top > svg {
    height: 36px;
    width: 36px;
}

.alert-top > span {
    font-weight: 600;
    font-size: 20px;
    margin: auto;
}

.alert-box .alert-body {
    font-size: 14px;
    color: var(--primary-color);
}
.alert-box.error .alert-top{
    color: red;
}
.alert-box.error > div {
    border-color: darkred;
}
.alert-box.success .alert-top{
    color: green;
}
.alert-box.success > div {
    border-color: darkgreen;
}